<template>
  <div>
    <Fieldset :toggleable="true"
    :collapsed.sync="isCollapsed" class="scholar-degree-container">
      <template #legend>
        Do you have any scholar degree?
      </template>
        <div class="line scholar-degree-container__in-line">
            <div class="column line__column">
                <label class="column__input-label"> High school: </label>
                <input class="column__input" v-model="highSchool"
                placeholder="Institute name"/>
            </div>
            <div class="column line__column">
                <label class="column__input-label"> Bachelor degree: </label>
                <input class="column__input" v-model="bachelor"
                placeholder="Institute name"/>
            </div>
        </div>
        <div class="line scholar-degree-container__in-line">
            <div class="column line__column">
                <label class="column__input-label"> Master: </label>
                <input class="column__input" v-model="master"
                placeholder="Institute name"/>
            </div>
            <div class="column line__column">
                <label class="column__input-label"> Phd: </label>
                <input class="column__input" v-model="phd"
                placeholder="Institute name"/>
            </div>
        </div>
    </Fieldset>
  </div>
</template>

<script>

export default {
  name: 'ScholarDegree',
  data() {
    return {
      highSchool: '',
      bachelor: '',
      master: '',
      phd: '',
      checked: false,
      isCollapsed: true,
    };
  },
  methods: {
    submit() {
      return {
        high_school: this.highSchool,
        bachelor_degree: this.bachelor,
        master: this.master,
        phd: this.phd,
      };
    },

    collapse() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
};
</script>

<style lang="scss" scoped>
.scholar-degree-container {
    margin: 2rem 0 2rem;
    border-radius: 25px;
    box-shadow: 5px 5px 10px rgba(0,0,0,0.09);
    &__in-line {
        display: flex;
        justify-content: center;
    }
}
.line {
  &__column {
    width: 50%;
    display: flex;
    justify-content: flex-end;
  }
}
.column {
  &__input {
    @include input(100%);
    width: 60%;
    margin-bottom: 1rem;
  }
  &__input-label {
    margin: .5rem .5rem 0 0;
  }
}
::v-deep .p-fieldset-legend {
  @include secondary-button();
  background-color: #f8f9fa;
  &:hover {
    border-color: $primary-color !important;
    color: $primary-color !important;
    background-color: #e6cfb4 !important;
  }
}
::v-deep .p-fieldset-legend a {
  color: $primary-color !important;
}
</style>
