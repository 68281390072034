<template>
  <div class="applicant-education">
    <Loading v-if="isLoading" />
    <div v-if="!isLoading">
      <div>
        <h1 class="applicant-education__tittle">Education</h1>
        <div class="applicant-education__content">
          <freelancerResume
            ref="freelancerResume"
            @enableButton="enableButton"
          />
          <scholarDegree ref="scholarDegree" />
        </div>
      </div>
      <footerBotons
        class="footer-container"
        @clickOnBack="onBackClick()"
        @clickOnContinue="onContinueClick()"
        :isEnable="isEnable"
      />
    </div>
  </div>
</template>

<script>
import FooterBotons from "../ContactData/Components/FooterBotons.vue";
import FreelancerResume from "./components/FreelancerResume.vue";
import ScholarDegree from "./components/ScholarDegree.vue";
import Loading from "../../components/Loading.vue";

export default {
  components: {
    FooterBotons,
    FreelancerResume,
    ScholarDegree,
    Loading,
  },
  name: "ApplicantEducation",
  data() {
    return {
      isEnable: false,
      isLoading: false,
    };
  },
  methods: {
    updateLocalStorageInfo(submitInfo) {
      // Retrieve freelancer information from local storage and update "freelancer" field
      const newFreelancerData = JSON.parse(
        localStorage.getItem("freelance-registry-information"),
      );
      newFreelancerData.education = submitInfo;
      localStorage.setItem(
        "freelance-registry-information",
        JSON.stringify(newFreelancerData),
      );
    },

    onContinueClick() {
      this.isLoading = true;
      const submitInfo = this.$refs.scholarDegree.submit();
      this.updateLocalStorageInfo(submitInfo);
      const link = this.$refs.freelancerResume.getLink();
      this.$refs.freelancerResume.submit();
      if (link !== "") {
        const regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/;
        if (regex.test(link)) {
          this.$router.push({ path: "/applicant-expertise" });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Network error",
            detail: "Invalid resume URL",
            life: 4000,
          });
        }
        this.isLoading = false;
      }
    },
    onBackClick() {
      this.$router.push({ path: "/contact-data" });
    },
    enableButton(e) {
      this.isEnable = e;
    },
  },
};
</script>

<style lang="scss" scoped>
.applicant-education {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__tittle {
    font-size: 2rem;
    margin: 1rem 6rem;
  }
  &__content {
    margin: 0 6rem 1rem;
  }
}

.footer-container {
  padding: 0 6rem 3rem 6rem;
}
</style>
