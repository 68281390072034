<template>
  <div class="share-resume-container">
    <div class="share-resume-container__header">
      <p>Please load your resume in a PDF file.</p>
      <button v-if="fileSelected" class="removeButton" @click="clearFile">Remove file</button>
    </div>
    <el-upload
      class="upload-demo"
      ref="uploader"
      drag
      name="resumen"
      accept="application/pdf"
      :action="backendURL"
      :headers="reqHeaders"
      :on-change="handleChange"
      :on-success="handleSuccess"
      :on-error="handleError"
      :auto-upload="false"
      :multiple="false"
      :disabled="fileSelected"
      :show-file-list="false">
      <div v-if="!fileSelected" class="el-upload__text">
        <i class="el-icon-upload"></i><br> Drop file here or <em>click to upload</em>
      </div>
      <div v-if="fileSelected" class="el-upload__text">
        <i class="el-icon-circle-check"></i><br> {{file.name}}</div>
    </el-upload>
    <h3 class="share-resume-container__sub-tittle">or</h3>
    <div class="line share-resume-container__in-line">
        <label class="line__input-label"> Link to your resume </label>
        <input class="line__input" placeholder="Enter link" v-model="resumeLink"
        @keyup="onUpload()"/>
        <img
        src="@/assets/images/GitHubIcon.png"
        alt="GitHub logo"
        class="line__logo"
        />
        <img
            src="@/assets/images/LinkedIn.png"
            alt="LinkedIn logo"
            class="line__logo"
        />
    </div>
  </div>
</template>

<script>

export default {
  name: 'FreelancerResume',
  data() {
    return {
      fileSelected: false,
      file: null,
      resumeLink: '',
      reqHeaders: {
        Authorization: `Bearer ${localStorage.getItem('brains-register-token')}`,
      },
      backendURL: '',
      attachend: '',
    };
  },
  created() {
    this.backendURL = `${process.env.VUE_APP_API}/api/bucket/resumen`;
  },
  methods: {
    getLink() {
      return this.resumeLink;
    },
    clearFile() {
      this.fileSelected = false;
      this.$refs.uploader.clearFiles();
      this.onUpload();
    },
    handleChange(file) {
      this.file = file;
      this.fileSelected = true;
      this.onUpload();
    },
    handleError() {
      this.$toast.add({
        severity: 'error',
        summary: 'Uploading error',
        detail: 'Unable to upload resume',
        life: 4000,
      });
      this.$router.push({ path: '/applicant-education' });
    },
    handleSuccess(response) {
      this.attachend = response;
      const newFreelancerData = JSON.parse(localStorage.getItem('freelance-registry-information'));
      newFreelancerData.freelancer.resumen = this.attachend;
      newFreelancerData.freelancer.external_link = this.resumeLink;
      localStorage.setItem('freelance-registry-information', JSON.stringify(newFreelancerData));
      this.$router.push({ path: '/applicant-expertise' });
    },
    onUpload() {
      if (this.fileSelected || this.resumeLink !== '') {
        this.$emit('enableButton', true);
      } else {
        this.$emit('enableButton', false);
      }
    },
    submit() {
      this.$refs.uploader.submit();
    },
  },
};
</script>

<style lang="scss" scoped>
.share-resume-container {
  &__sub-tittle {
    margin: 2rem auto;
    text-align: center;
  }
  &__in-line {
    display: flex;
    justify-content: center;
  }
  &__header {
    display: flex;
    justify-content: space-between;
  }
}
.line {
  &__input {
    @include input(100%);
    width: 40%;
    margin-bottom: 1rem;
    margin-right: .5rem;
  }
  &__input-label {
    margin: .5rem .5rem 0 0;
  }
  &__logo {
    width: 26px;
    height: 26px;
    margin: .3rem .2rem;
  }
}
::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 100%;
  border-radius: 25px;
  &:hover {
    border-color: $primary-color;
  }
}
em {
  color: $primary-color !important;
}
.removeButton {
  @include secondary-button();
  height: 2.7rem;
}
.el-icon-circle-check {
  font-size: 67px;
  color: #C0C4CC;
  margin: 40px 0 16px;
  line-height: 50px;
}
</style>
